<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Title Wise Collection Reports
            <v-spacer></v-spacer>
            <v-download-column-select
              v-if="downloadUrl && form.items.data.length"
              optionType="radio"
              @selectedItem="selectedColumns"
              :showExcel="selectedColumnType === 'list_view'"
              :downloadColumns="downloadColumns"
              @onDownload="downloadRecord"
            >
              Title Wise Report
            </v-download-column-select>
            <!-- <print-buttons
              :passAuthToReport="true"
              v-if="downloadUrl && form.items.data.length"
              :download-url="downloadUrl"
              :pdf="true"
              :excel="true"
              labelExcel="Download Excel"
              labelPdf="Download Pdf"
            ></print-buttons> -->
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm2>
                <v-grade-field multiple v-model="grades" label="Select Grades">
                </v-grade-field>
              </v-flex>
              <v-flex xs3 sm2>
                <v-section-field
                  :gradeId="grades.length === 1 ? grades[0] : null"
                  v-model="section"
                  label="Select Section"
                >
                </v-section-field>
              </v-flex>

              <v-flex xs3 sm2>
                <v-calendar-field
                  id-val="from_date"
                  v-model="frmDate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs3 sm2>
                <v-calendar-field
                  id-val="to_date"
                  v-model="toDate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex x3 sm3>
                <transaction-via v-model="transaction_by"></transaction-via>
              </v-flex>
              <v-flex xs1>
                <search-button
                  :disabled="transaction_by.length < 1"
                  permission="receipt-read"
                  @action="get()"
                >
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            hide-default-footer
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr @click="expanded = !expanded" style="cursor: pointer">
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.fee_head }}
                </td>
                <td class="text-xs-left">
                  {{
                    (
                      item.sum_amount + parseFloat(item.sum_instant_discount)
                    ).currency()
                  }}
                </td>
                <td class="text-xs-left">
                  {{ parseFloat(item.sum_instant_discount).currency() }}
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr v-if="totalSum > 0">
                <td :colspan="headers.length - 2">
                  <strong>Sub Total:</strong>
                </td>
                <td>
                  <strong>{{ totalSum.currency() }}</strong>
                </td>
                <td>-</td>
              </tr>
              <tr v-if="totalSum > 0">
                <td :colspan="headers.length - 2">
                  <strong>Title wise Discount:</strong>
                </td>
                <td>-</td>
                <td>
                  <strong>{{ totalInstatanDiscountSum.currency() }}</strong>
                </td>
              </tr>
              <tr v-if="totalSum > 0">
                <td :colspan="headers.length - 2">
                  <strong>Bulk Discount:</strong>
                </td>
                <td>-</td>
                <td>
                  <strong
                    >{{
                      (
                        totalInstantDiscount - totalInstatanDiscountSum
                      ).currency()
                    }}
                  </strong>
                </td>
              </tr>
              <tr v-if="totalSum > 0">
                <td :colspan="headers.length - 2">
                  <strong>Grand Total:</strong>
                </td>
                <td>
                  <strong>
                    {{
                      (
                        totalSum -
                        (totalInstantDiscount -
                          totalInstatanDiscountSum +
                          totalInstatanDiscountSum)
                      ).currency()
                    }}
                  </strong>
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import TransactionVia from "@/components/app/TransactionVia";
import { TOTAL_TRANSACTION_VIA } from "@/config";
import ls from './../../../../library/Storage';
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";
import auth from "../../../../library/Auth";

import {redirectDownloadUrl} from "../../../../library/helpers"


export default {
  mixins: [Mixins],
  components: {
    TransactionVia,
  },
  data: () => ({
    selectedColumnType: "list_view",
    downloadColumns: [
      { value: "grid_view", name: "Grid View", selected: false },
      { value: "list_view", name: "List View", selected: true },
    ],
    transaction_by: ["Cash", "Cheque", "Khalti", "Esewa", "Advance","eSewa-QR"],
    
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
      },
      "/api/eod-collection"
    ),
    pagination: {
      rowsPerPage: 100,
      descending: true,
    },
    frmDate:
      nd.getYear() + "-" + nd.getMonth().pad() + "-" + nd.getDate().pad(),
    grades: [],
    section: "",
    toDate: nd.format("YYYY-MM-DD"),
    downloadUrl: "",
    totalSum: 0,
    totalInstatanDiscountSum: 0,
    month: "",
    rowsPerPageItems: [5, 10, 25, 50, 75],
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Fee Head", align: "left", value: "fee_head" },
      { text: "Amount", align: "left", value: "amount", width: 200 },
      {
        text: "Instant Discount",
        align: "left",
        value: "instant_discount",
        width: 150,
      },
    ],
    totalInstantDiscount: 0,
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    month: function (value) {
      this.form.items.data = [];
    },
  },

  methods: {
    selectedColumns(column) {
      this.selectedColumnType = column.value;
    },
    downloadRecord({ type, columnArr }) {
      let via =
        this.transaction_by.length > TOTAL_TRANSACTION_VIA
          ? "all"
          : this.transaction_by.join(",");
      let grades = ''
      if (this.grades.length > 0) {
        grades = this.grades.join(",");
      }
      let displayType=columnArr.includes("list_view") ? "list" : "grid"
      // console.log(this.downloadUrl,this.grades.join(","))
      //https://reporting.sahakaryatech.com/download/billing/sod-feehead/1:40?fromDate=2079-11-07&toDate=2079-12-07&accountService=&via=Cash,Cheque,Khalti,Esewa&grades=36,64,37,61,38&section=&type=pdf&displayType=list&token=877

      let extraParam = 'fromDate='+this.frmDate+"&toDate="+this.toDate+"&accountService="+
                        "&via="+via+"&grades="+grades+"&type="+type+"&displayType="+displayType
      
      redirectDownloadUrl({
          uri: "/download/billing/sod-feehead",
          queryString:extraParam,
      });
      // window.open(
      //   `${this.downloadUrl}${type}&displayType=${
      //     columnArr.includes("list_view") ? "list" : "grid"
      //   }&token=${auth.user().id}`
      // );
    },
    get() {
      let extraParam =
        "fromDate=" +
        this.frmDate +
        "&toDate=" +
        this.toDate +
        "&reporting_type=eod-collection";

      let via =
        this.transaction_by.length > TOTAL_TRANSACTION_VIA
          ? "all"
          : this.transaction_by.join(",");

      extraParam += "&via=" + via;

      if (this.grades.length > 0) {
        extraParam += "&grades=" + this.grades.join(",");
      }

      if (this.section) {
        extraParam += "&section=" + this.section;
      }

      this.form.get(null, this.queryString(extraParam)).then(({ data }) => {
        this.totalSum = 0;
        this.totalInstatanDiscountSum = 0;
        this.totalInstantDiscount = 0;
        if (data.cash_cheque && data.cash_cheque.length) {
          const cash_cheque = data.cash_cheque[0];
          this.totalInstantDiscount = cash_cheque.instant_discount;
        }
        data.data.map((res) => {
          this.totalSum +=
            res.sum_amount + parseFloat(res.sum_instant_discount);
          this.totalInstatanDiscountSum += parseFloat(res.sum_instant_discount);
        });
        this.pagination.totalItems = data.meta.total;
        this.downloadUrl = data.download_url;
      });
    },

    viewStatement(sod_date) {
      this.$router.push({
        name: "eod-collection-report-detail",
        params: {
          sod: sod_date,
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
